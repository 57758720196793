<template >
  <div id="jugador-profile">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>
    <div class="body-pro-player">
      <img
        src="../assets/primer-equipo-banner.png"
        alt="hpp"
        class="header-pro-player pc"
      />
      <img
        src="../assets/primer-equipo-ban-mo.png"
        alt="hpp"
        class="header-pro-player mob"
      />

      <div class="player-data-cont">
        <div class="col-player">
          <div class="gray-data-cont">
            <div class="name-video-cont">
              <div class="name-pos-cont">
                <h4>{{ this.playerdata.type_name }}</h4>
                <div class="name-cont">
                  <h1 class="number pc">{{ this.playerdata.num }}</h1>
                  <div class="name-stru">
                    <h3>{{ splitName(this.playerdata.name) }}</h3>
                    <h3>
                      <span>{{ splitLastName(this.playerdata.name) }}</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="video-cont name-cont-vid">
                <div
                  class="l-cont"
                >
                  <a>
                    <div class="l-div">
                      <img
                        src="../assets/vim1.png"
                        width="377"
                        height="350"
                        alt="e"
                        class="l-m-content"
                      />
                      <img
                        src="../assets/red-rec.png"
                        width="118"
                        height="12"
                        alt="rr"
                        class="red-rec"
                      />
                      <img
                        src="../assets/play1.png"
                        alt="play"
                        class="play-video-icon"
                      />
                    </div>
                  </a>
                  <div class="titulo-vim">
                    <h6 class="date">
                      HACE 2 DIAS
                    </h6>
                    <a>
                      <h4 class="titulo">REVIVE LAS MEJORES JUGADAS</h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="game-data-player">
              <div class="data-cont">
                <h2>87</h2>
                <h6>Partidos Jugados</h6>
              </div>
              <div class="data-cont">
                <h2>54</h2>
                <h6>Duelos ganados</h6>
              </div>
              <div class="data-cont">
                <h2>19</h2>
                <h6>Pases con éxito</h6>
              </div>
              <div class="data-cont">
                <h2>6</h2>
                <h6>Goles</h6>
              </div>
            </div>
          </div>
          <div class="video-cont name-cont-vid-mo">
                <div
                  class="l-cont"
                >
                  <a>
                    <div class="l-div">
                      <img
                        src="../assets/vim1.png"
                        width="377"
                        height="350"
                        alt="e"
                        class="l-m-content"
                      />
                      <img
                        src="../assets/red-rec.png"
                        width="118"
                        height="12"
                        alt="rr"
                        class="red-rec"
                      />
                      <img
                        src="../assets/play1.png"
                        alt="play"
                        class="play-video-icon"
                      />
                    </div>
                  </a>
                  <div class="titulo-vim">
                    <h6 class="date">
                      HACE 2 DIAS
                    </h6>
                    <a>
                      <h4 class="titulo">REVIVE LAS MEJORES JUGADAS</h4>
                    </a>
                  </div>
                </div>
              </div>
          <div class="personal-data-pla">
            <div class="per-data-cont">
              <p>LUGAR DE NACIMIENTO</p>
              <h4>HERMOSILLO, SONORA</h4>
            </div>
            <div class="per-data-cont">
              <p>FECHA DE NACIMIENTO</p>
              <h4>07/10/1987</h4>
            </div>
            <div class="per-data-cont">
              <p>PESO</p>
              <h4>90KG</h4>
            </div>
            <div class="per-data-cont">
              <p>ALTURA</p>
              <h4>197CM</h4>
            </div>
          </div>
        </div>
        <div class="col-player pla-pic">
          <h1 class="number mov">{{ this.playerdata.num }}</h1>
          <img
            :src="url + '/get-player-image/' + this.playerdata.image"
            alt="player"
            class="player-cont-img"
          />
        </div>
      </div>
      <div class="cont-player-news">

        <div class="news-mobile">
              <div class="news-cont">
                <div class="news-data-cont">
                  <h4 class="title-news">
                    MORENO VA POR MÁS GOLES ANTE VENADOS
                  </h4>
                  <p class="news-text">
                    Con un solitario gol del “Guamerucito” Brian García...
                  </p>
                  <img src="../assets/rec-red.png" alt="redrec" class="red-rec" />
                </div>
                <img src="../assets/nw-4.png" alt="news" class="img-news-cont" />
              </div>
        </div>


        <vueper-slides
          :bullets="false"
          :touchable="false"
          class="player-news-slide no-shadow"
        >
          <vueper-slide>
            <template #content>
              <div class="news-cont">
                <div class="news-data-cont">
                  <h4 class="title-news">
                    MORENO VA POR MÁS GOLES ANTE VENADOS
                  </h4>
                  <p class="news-text">
                    Con un solitario gol del “Guamerucito” Brian García...
                  </p>
                  <img src="../assets/rec-red.png" alt="redrec" class="red-rec" />
                </div>
                <img src="../assets/nw-4.png" alt="news" class="img-news-cont" />
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>
    <!-- <JugadorPerfilTab/>-->

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import JugadorPerfilTab from "../components/JugadorPerfilTab";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapActions } from "vuex";

export default {
  components: {
    HeaderHome,
    Footer,
    JugadorPerfilTab,
    VueperSlides,
    VueperSlide,
  },
  created() {
    this.findByNamePre();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    playerdata() {
      return this.$store.getters["players/data6"];
    },
  },
  methods: {
    ...mapActions("players", ["findByNamePre"]),

    findByNamePre: async function () {
      let name = this.$route.params.name;

      let result = await this.$store.dispatch("players/findByNamePre", {
        option: "players/name",
        name: name,
      });
      this.info = result;
      return result;
    },

    splitName(name) {
      let splitname = name.split(" ");
      if (splitname.length == 3) {
        return splitname[0];
      } else if (splitname.length == 4) {
        return splitname[0];
      } else if (splitname.length == 5) {
        return splitname[0];
      } else if (splitname.length == 6) {
        return splitname[0];
      } else {
        return splitname[0];
      }
    },

    splitLastName(name) {
      let splitname = name.split(" ");
      if (splitname.length == 3) {
        return splitname[1];
      } else if (splitname.length == 4) {
        return splitname[2];
      } else if (splitname.length == 5) {
        return splitname[3];
      } else if (splitname.length == 6) {
        return splitname[2];
      } else {
        return splitname[1];
      }
    },
  },
};
</script>

<style scoped>
.header-pro-player.pc {
  height: 14.792vw;
  width: 100%;
  display: block;
}
.header-pro-player.mob {
  display: none;
}
.name-cont {
  display: flex;
  align-items: center;
}
.name-video-cont {
  height: 24.792vw;
  width: auto;
  display: flex;
  align-items: center;
}
.gray-data-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 36.25vw;
  background: url("../assets/fondiu-player.png");
  background-size: 100% 36.25vw;
  background-repeat: no-repeat;
  width: 60.563vw;
}
.name-pos-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.name-pos-cont h4 {
  font-size: 1.563vw;
  line-height: 90.49%;
  color: #b70d1d;
  font-family: "Rajdhani";
  margin-bottom: 2.917vw;
  text-transform: uppercase;
}
.name-pos-cont .number {
  font-size: 10.417vw;
  font-family: "Rajdhani";
  color: #b70d1d;
}
.number.pc{
  display: block;
}
.number.mov{
  display: none;
}
.name-pos-cont .name-stru h3 {
  font-size: 4.688vw;
  line-height: 4vw;
  margin-bottom: 0;
  font-family: "Rajdhani";
  color: #01203a; 
    margin-right: 2vw;
}
.name-pos-cont .name-stru h3 span {
  font-family: "Rajdhani";
}
.game-data-player {
  display: flex;
  margin-bottom: 4.74vw;
  width: 100%;
  justify-content: space-evenly;
}
.personal-data-pla {
  display: flex;
  margin-top: 2.396vw;
  color: white;
  justify-content: space-between;
  width: 90%;
}
.player-data-cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 2.031vw solid #01203a;
  border-left: 0.885vw solid#01203A;
  border-right: 0.885vw solid#01203A;
  border-bottom: 0.885vw solid#01203A;
  background: #01203a;
}
.col-player {
  width: 61.563vw;
}
.col-player.pla-pic {
  width: 36.667vw;
  background: #e1e1e1;
  background-image: url("../assets/dots-1.png");
  background-size: 14.948vw;
  background-repeat: no-repeat;
  background-position-x: right;
}
.data-cont {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.data-cont h2 {
  font-size: 3.906vw;
  line-height: 110.49%;
  text-align: center;
  color: #968220;
  font-family: "Rajdhani";
}
.data-cont h6 {
  font-size: 1.146vw;
  line-height: 110.49%;
  text-align: center;
  color: #686868;
  font-family: "Rajdhani";
}
.per-data-cont p {
  font-size: 0.781vw;
  line-height: 90.49%;
  color: #ffffff;
  margin-bottom: 0.3vw;
  font-family: "Rajdhani";
}
.per-data-cont h4 {
  font-size: 1.458vw;
  line-height: 90.49%;
  font-family: "Rajdhani";
}
.player-cont-img {
  width: 100%;
  height: 42.396vw;
}


/*video */
.l-cont {
    margin: 0 0.3vw 0 0 !important;
}.l-m-content {
    width: 100%;
    height: 19vw;
}.l-div {
    width: 19.688vw;
    height: 19vw;
}img.img-news-cont {
    width: 19.896vw;
    height: 35.938vw;
    object-fit: cover;
    filter: grayscale(100%) contrast(100%);
}.news-data-cont {
    width: 19.896vw;
    height: 35.938vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 990;
    background: #0023408c;
}.cont-player-news {
    height: 41.042vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/*news*/
.news-cont {
    width: 19.896vw;
    height: 35.938vw;
}.vueperslides__parallax-wrapper {
    padding-bottom: 0!important;
    height: 37vw!important;
}h4.title-news {
    padding: 0 2vw;
    font-size: 1.563vw;
    line-height: 1.771vw;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'Rajdhani';
}p.news-text {
    font-size: 0.833vw;
    line-height: 127%;
    color: #A0A0A0;
    padding: 0 2vw 2vw;
}.news-cont:hover .red-rec {
    width: 100%;
}.name-cont-vid{
  display: block;
}.name-cont-vid-mo{
  display: none;
}.news-mobile{
  display: none;
}

@media (max-width: 768px) {
  .body-pro-player {
    padding-top: 32.367vw;
  }
  .header-pro-player.mob {
    height: 69.082vw;
    width: 100%;
    display: block;
  }
  .header-pro-player.pc {
    display: none;
  }.player-data-cont{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    background: #01203a;
    flex-direction: column-reverse;
}.col-player.pla-pic {
    width: 100%;
    background: #ffffff;
    background-image: none;
}.player-cont-img {
    width: 100%;
    height: 100%;
}.name-cont-vid{
  display: none;
}.name-cont-vid-mo{
  display: block;
}.col-player {
    width: 100%;
    background: #f9f8f8;
}.gray-data-cont{
    height: 120.773vw;
    background-image: url('../assets/cuernifero-3.png');
    background-size: 75.121vw 104.831vw;
    background-position-x: right;
    background-position-y: center;
    background-color:#f9f8f8;
    width: 100%;
}.name-pos-cont h4 {
    font-size: 6.039vw;
    margin-bottom: 2.917vw;
    margin-top: 10.87vw;
}
.number.pc{
  display: none;
}
.number.mov{
  display: block;
  position: absolute;
  font-size: 16.908vw;
  color: #B70D1D;
  font-family: 'Rajdhani';
  padding-top: 2vw;
  padding-left: 6vw;
}.name-pos-cont .name-stru h3 {
    font-size: 10.870vw;
    line-height: 10.870vw;
    margin-right: 2vw;
    text-align: center;
}.name-video-cont {
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
}.game-data-player {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}.data-cont h2 {
    font-size: 14.493vw;
    line-height: 14.493vw;
}.data-cont h6 {
    font-size: 3.865vw;
    line-height: 3.865vw;
}.game-data-player {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 78%;
    height: 50%;
}.data-cont {
    width: 33vw;
}.l-cont {
    display: flex;
    width: 100%;
    margin: 0 auto !important;
    height: 91.063vw;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}.l-div {
    width: 91.063vw;
    height: 91.063vw;
}.l-m-content {
    width: 91.063vw;
    height: 91.063vw;
    border-radius: 0;
}.red-rec {
    width: 37.146vw;
    display: block;
    height: 4.5vw;
}.l-cont:hover .red-rec {
    width: 91.063vw;
    height: 4.5vw;
}.play-video-icon {
    width: 91.063vw;
    height: 91.063vw;
    margin-left: 0;
    margin-top: 0;
    padding: 34vw;
}.play-video-icon:hover {
    padding: 27.5vw;
}.l-cont .titulo-vim .titulo {
    font-size: 5.623vw;
    line-height: 5.179vw;
    color: white;
}.titulo-vim {
    width: 91vw;
    position: absolute;
    padding: 7vw;
}.l-cont .titulo-vim .date {
    color: #ffffff;
    font-size: 3.174vw;
    line-height: 3.623vw;
    background-size: 3.623vw;
}.video-cont {
    padding-top: 15vw;
}.personal-data-pla {
    display: flex;
    margin-top: 0;
    padding-top: 2.396vw;
    color: white;
    justify-content: space-between;
    width: 100%;
    height: 51.932vw;
    background: #b70d1d;
    flex-wrap: wrap;
    padding: 12vw;
}.per-data-cont p{
    font-size: 2.781vw;
}.per-data-cont h4 {
    font-size: 3.458vw;
}.per-data-cont {
    width: 33vw;
}.vueperslides.player-news-slide {
    display: none;
}.cont-player-news {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}.news-data-cont {
    width: 100%;
    height: 49.517vw;
}img.img-news-cont {
    width: 96.6vw;
    height: 49.517vw;
    object-fit: cover;
    filter: grayscale(100%) contrast(100%);
}h4.title-news {
    padding: 0px 6vw;
    font-size: 3.563vw;
    line-height: 3.771vw;
}p.news-text {
    font-size: 2.833vw;
    line-height: 127%;
    width: 100%;
    color: #A0A0A0;
    padding: 0 5vw 4vw;
}.news-mobile{
  display: block;
}



}
</style>