<template >
  <div class="calendar-cont">
    <div class="calendar-header">
      <h1>{{this.liga}}</h1>
    </div>
    <Tabs>
      <TabItem id="primer-equipo" :style="{ display: displayLEMX[$route.path] }">
        <template #name>
          <div class="team-type-header" id="tabactiva" v-bind:class="{activa: activeLEMX[$route.path]}" @click="alturaMaxima() & displayTab() & ligaTrue()">
            <h4>EXPANSIÓN</h4>
          </div>
        </template>

        <PlayerFirstTeam v-if="tabliga"/>
      </TabItem>
      <TabItem id="segundo-equipo" :style="{ display: displayP[$route.path] }">
        <template #name>
          <div class="team-type-header" id="tabactiva" v-bind:class="{activa: activeP[$route.path]}" @click="displayTab() & premierTrue()"> 
            <h4>PREMIER</h4>
          </div>
        </template>

        <PlayerPremierTeam v-if="tabliga()"/>
      </TabItem>
      <TabItem id="tercer-equipo" :style="{ display: displayTDP[$route.path] }">
        <template #name>
          <div class="team-type-header" id="tabactiva" v-bind:class="{activa: activeTDP[$route.path]}" @click="displayTab() & terceraTrue()">
            <h4>TERCERA (TDP)</h4>
          </div>
        </template>

        <PlayerTDP v-if="tabliga()"/>
      </TabItem>
    </Tabs>
  </div>
</template>
<script>
import { Tabs, TabItem } from "vue-material-tabs";
import PlayerFirstTeam from '../components/PlayerFirstTeam';
import PlayerPremierTeam from '../components/PlayerPremierTeam';
import PlayerTDP from '../components/PlayerTDP';

export default {
  components: {
    Tabs,
    TabItem,
    PlayerFirstTeam,
    PlayerPremierTeam,
    PlayerTDP
  },
  data() {
    return {
      isActive: true,
      ligab: false,
      premierb: false,
      tercerab: false,
      liga: 'EXPANSIÓN',
      displayLEMX: {
        '/jugadores': 'flex!important',
        '/jugadores/premier': 'none!important',
        '/jugadores/tercera': 'none!important',
      },
      displayP: {
        '/jugadores': 'none!important',
        '/jugadores/premier': 'flex!important',
        '/jugadores/tercera': 'none!important',
      },
      displayTDP: {
        '/jugadores': 'none!important',
        '/jugadores/premier':'none!important',
        '/jugadores/tercera': 'flex!important',
      },
      activeLEMX: {
        '/jugadores': true,
        '/jugadores/premier': false,
        '/jugadores/tercera': false,
      },
      activeP: {
        '/jugadores': false,
        '/jugadores/premier': true,
        '/jugadores/tercera': false,
      },
      activeTDP: {
        '/jugadores': false,
        '/jugadores/premier': false,
        '/jugadores/tercera': true,
      },
    }
  },
  methods: {
    displayTab(){
      document.getElementById('tabactiva').classList.remove('activa');
    },
    alturaMaxima(){
      document.getElementById('primer-equipo').style.maxHeight = 'fit-content',
      document.getElementById('primer-equipo').style.maxWidth = 'fit-content';
    },
    ligaTrue(){
      return this.liga = 'EXPANSIÓN',this.$router.push('/jugadores');
    },
    premierTrue(){
      return this.liga = 'PREMIER',this.$router.push('/jugadores/premier');
    },
    terceraTrue(){
      return this.liga = 'TERCERA (TDP)',this.$router.push('/jugadores/tercera');
    },
    tabliga(){
    if (this.$route.path === "/jugadores/premier") {
       return this.liga = 'PREMIER'
    }
    else if (this.$route.path === "/jugadores") {
       return this.liga = 'EXPANSIÓN'
    }
    else if (this.$route.path === "/jugadores/tercera") {
       return this.liga = 'TERCERA (TDP)'
    }
  }
  }

  }



</script>
<style scoped>
#primer-equipo {
    display: flex!important;
}#calendario .tabla-liga {
    padding: 2vw 0;
    width: 93vw;
}.team-type-header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activa {
    color: white!important;
    background: #B70D1D;
    padding-right: 5vw!important;
    background-size: 2.865vw!important;
    background-position-x: 14.115vw!important;
    background-position-y: center;
    background-repeat: no-repeat;
    display: flex;
    background-image: url('../assets/arrow2.png')!important;
}.activa:hover {
    padding-right: 0;
}
</style>